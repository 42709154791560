const state_original = {
    MLS: null,
    Residential: true,
    Commercial: false,
    ForSale: true,
    ForRent: false,
    PriceFrom: null,
    PriceTo: null,
    ProvinceIds: [],
    TownIds: [],
    ZoneIds: [],
    PropertyMacroTypeId: null,
    PeriodicalPaymentTypeId: null,
    ContractTypeId: null,
    ContractTypeIds: [],
    MinimumBedrooms: null,
    MaximumBedrooms: null,
    MinimumBathrooms: null,
    MaximumBathrooms: null,
    SelectedInternalFeatures: [],
    SelectedExternalFeatures: [],
    SelectedInternalInventories: [],
    SelectedMarketingStatuses: [],
    SelectedMarketingCategories: [],
    SelectedPropertyTypes: [],
    MarketStatusId: null,
    Favourites: [],
    ListingsToExclude: [],
    Impersonate: null,
    AssociateId: null,
    SortBy: null,
    MinimumInternalSQM: null,
    MinimumExternalSQM: null,
    MinimumTotalSQM: null,
    MinimumPlotSize: null,
    page: 1
};
// convert to json & back to clone object, so as to avoid referencing when changing
const state = JSON.parse(JSON.stringify(state_original));

const getters = {
    get(state) {
        return state;
    }
};

const mutations = {
    setObject(state, object) {
        Object.assign(state, object);
    },

    setSearchCriteria(state, { key, value }) {
        //format boolean values
        if (typeof value == 'string') {
            if (value.toLowerCase() == 'true') { //is it bool
                value = true;
            }
            else if (value.toLowerCase() == 'false') { //is it bool
                value = false;
            }
            else if (value.indexOf(',') > -1) { //is it an array
                value = value.split(',');

                value = value.filter(Number) 
            }
            else { //is it json ?
                try {
                    JSON.parse(str);
                    value = JSON.parse(value);
                } catch (e) {

                }
            }
        }

        state[key] = value;
    },
};

const actions = {
    updateSearchCriteria({ dispatch, commit }, criteria) {
        commit('setSearchCriteria', { key: criteria.key, value: criteria.value });
    },

    updateFullSearchCriteria({ dispatch, commit }, criteria) {
        commit('setObject', criteria);
    },

    clearSearchCriteria({ dispatch, commit }) {
        commit('setObject', state_original);
    },

    saveSearch({dispatch, commit, state}) {
        axios.post('/saved-searches', state )
        .then(response => {
            console.log(response);
        });

        return false;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};