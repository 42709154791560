<template>
    <div class="section--agents-of-branch--container"> 

        <div class="agents-of-branch--row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5"> 
            <loading-component v-if="isLoading"></loading-component>
            <div class="col mb-15 mb-lg-30" v-for="agent in agents">
                <agent-card :key="agent.AssociateId" :agent="agent"></agent-card>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['branchId'],

        data: function() {
            return {
                agents: [],
                isLoading: true,
            }
        },
        
        mounted() {
            this.getAgents();
        },

        methods: {
            getAgents: function() {

                this.isLoading = true;

                var url = '/api/branches/'+this.branchId+'/agents'
                axios.get(url)
                .then(response => {
                    this.isLoading = false;
                    this.agents = response.data.data;
                });
            },
        }
    }
</script>