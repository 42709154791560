 <template>
	<div class="agent-preview-card">
		<favourite-component type="agent" :id="agent.AssociateId"></favourite-component>
		<div class="preview-card--image">
			<a v-if="this.viewProfile" :href="'/agent/'+agent.ShortLink">
				<div class="preview-card--image-bg" :style="'background-image: url('+agent.Photos[2].ImageURL+')'"></div>
			</a>
			<div v-else class="preview-card--image-bg" :style="'background-image: url('+agent.Photos[2].ImageURL+')'"></div>
		</div>
		<div class="preview-card--content">
			<a v-if="this.viewProfile" class="preview-card--content-name" :href="'/agent/'+agent.ShortLink">{{ agent.Name }} {{ agent.Surname }}</a>
			<span v-else class="preview-card--content-name text-primary">{{ agent.Name }} {{ agent.Surname }}</span>
			<div class="preview-card--content-address">{{ agent.OfficeName }}</div>

			<div v-if="agent.AverageTestimonialRating != 0" class="mt-5">
				<span itemscope itemtype="http://schema.org/Product">

					<meta itemprop="image" :content="agent.Photos[2].ImageURL">
					<meta itemprop="name" :content="agent.Name + ' ' + agent.Surname">

					<span itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
						<meta itemprop="ratingValue" :content="agent.AverageTestimonialRating" />
						<meta itemprop="bestRating" content="5" />
						<meta itemprop="ratingCount" :content="agent.NumberOfTestimonials" />
					</span>

					<icon-star class="text-warning" height="15"></icon-star>
					<icon-star :class="agent.AverageTestimonialRating >= 2 ? 'text-warning' : 'text-black-50'" height="15"></icon-star>
					<icon-star :class="agent.AverageTestimonialRating >= 3 ? 'text-warning' : 'text-black-50'" height="15"></icon-star>
					<icon-star :class="agent.AverageTestimonialRating >= 4 ? 'text-warning' : 'text-black-50'" height="15"></icon-star>
					<icon-star :class="agent.AverageTestimonialRating >= 5 ? 'text-warning' : 'text-black-50'" height="15"></icon-star>

				</span><!--end of google product-->
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		props: {
			agent: {
				type: [Object, Array],
				default: {}
			},
			viewProfile: {
				type: Boolean,
				default: true
			},
		},
	}
</script>


