<template>
    <div class="section--property-cards-minimal">  
        
        <h5 class="offset-1 col-10 px-0 mt-30">{{ totalProperties + ' ' + trans('pages.listings') }}</h5>
        
        <loading-component v-if="isLoading"></loading-component>

        <section v-if="properties && properties.length > 0">
            <property-card-single v-for="property in properties" v-bind:key="property.mls" :property="property"></property-card-single>            
        </section>
        
        <section class="col py-15" v-if="properties && !properties.length > 0 && isLoading == false">
            <h3>{{ trans('properties.no_properties_found') }}</h3>
        </section>
        
        <section class="section--pagination" v-if="pagination.length > 3">
            <div v-if="pagination" v-for="page in pagination">  
                <div class="pagination--button" :class="{ 'pagination--selected' : page.page == current_page, 'disable' : page.page == null }" v-on:click="executePagination(page.page)"><p>{{ page.text }}</p></div>
            </div>
        </section> 
              
    </div>
</template>

<script>
    export default {
        props: ['criteria'],
        data: function () {
            return {
                properties: null,
                totalProperties: '0',
                pagination: [],
                current_page: 1,
                criterias: {
                    Residential: null,
                    Commercial: null,
                    ForSale: null,
                    ForRent: null,
                    PriceFrom: null, 
                    PriceTo: null, 
                    ProvinceIds: [],
                    TownIds: [],
                    ZoneIds: [],
                    PropertyMacroTypeId: null,
                    SelectedPropertyTypes: [],
                    MinimumBedrooms: null,
                    MaximumBedrooms: null,
                    MinimumBathrooms: null,
                    MaximumBathrooms: null,
                    SelectedInternalFeatures: [],
                    SelectedExternalFeatures: [],
                    SelectedInternalInventories: [],
                    SelectedMarketingStatuses: [],
                    SelectedMarketingCategories: [],
                    page: null,
                    Take: this.getShowPropertyCount(),                    
                },
                isLoading: true,
                maxPaginationNext: 3,
                maxPaginationPrev: 3,
            }
        },

        mounted() {
            //get current page from url and store it in var
            this.criterias.page = 1;
            
            //overwrite properties
            Object.assign(this.criterias, this.criteria);

            //get property listings from api
            this.getProperties(this.criterias.page);
        },

        methods: {
            
            executePagination: function(page) {
                if (page == null) {
                    return;
                }

                this.criterias.page = page;
                this.current_page = page;
                
                this.getProperties()
            },

            getShowPropertyCount: function() {
                return 9;
            },

            getPropertiesSearchParameters: function() {
                //not using URLSearchParams due to non IE support
                var urlParams = [];
                
                for (let key in this.criterias){
                    if(this.criterias.hasOwnProperty(key)){

                        // if empty skip / do not include in url
                        if (this.criterias[key] == null || 
                        (Array.isArray(this.criterias[key]) && this.criterias[key].length == 0)) {
                            continue;
                        }

                        //if array join them up ex . 1,2,3,4, else just display value ex . 1 
                        var value = (Array.isArray(this.criterias[key])) ? this.criterias[key].join(',')
                                                                         : this.criterias[key];

                        urlParams.push(key + '=' + value);
                    }
                }

                //join all paras ex. a=1,2,3&b=4&c=5,6
                return urlParams.join('&');
            },

            getProperties: function() {         
                this.isLoading = true;

                //assign this to that, so as to be accessibe in axios return
                var that = this;

                axios.get('/api/properties?'+that.getPropertiesSearchParameters())
                .then(function (response) {
                    //that.setPagination(that.current_page, that.getShowPropertyCount(), response.data.data.TotalSearchResults);
                    that.setPagination(that.current_page, response.data.data.TotalSearchResults);

                    that.properties = response.data.data.Properties;
                    that.totalProperties = response.data.data.TotalSearchResults;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);

                })
                .finally(function () {
                    that.isLoading = false;
                });
            },

            setPagination( this_page, total_count) {
                var this_count = this.getShowPropertyCount();
                var total_pages = Math.ceil(total_count / this_count);

                this.pagination = [];
                this.pagination.push({
                    text: '< prev',
                    page: (this_page > 1) ? this_page - 1 : null
                });

                for (var i = this_page - this.maxPaginationPrev; i < this_page; i++){
                    if (i < 1) {
                        continue;
                    }

                    this.pagination.push({
                        text: i,
                        page: i
                    });
                }

                 this.pagination.push({
                    text: this_page,
                    page: this_page
                });

                for (var i = this_page + 1; i <= this_page + this.maxPaginationNext; i++){
                    if (i > total_pages) {
                        continue;
                    }
                    
                    this.pagination.push({
                        text: i,
                        page: i
                    });
                }

                if ((this_page + this.maxPaginationNext) < total_pages) {
                    this.pagination.push({
                        text: '...',
                        page: null
                    });
                    this.pagination.push({
                        text: total_pages,
                        page: total_pages
                    });
                }

                this.pagination.push({
                    text: 'next >',
                    page: (this_page < total_pages) ? this_page + 1 : null
                });
            },

                       
        },
    }
</script>
