<template>
    <div class="property-card property-card-single" :class="property.ContractType.toLowerCase()">
        <div class="property-card--container shadow-sm">
           
            <favourite-component v-if="favourite" type="property" :id="property.MLS"></favourite-component>

            <div class="property-card--information">

                <template v-if="this.isLoading">
                    <loading-component />
                </template>
                
                <div v-else>
                    <a :href="this.propertyLink">
                        <div class="property-card--image">
                            <div class="bg-primary bg-cover bg-center" :style="'background-image:url('+property.Image+')'"></div>
                            <div class="bg-absolute bg-linear-gradient-property"></div>
                            <div class="property-card--tags"> 
                                <div class="tag sale rent"><span>{{ property.TransactionType }}</span></div>
                                <div v-if="isCollection" class="tag collection"><span>Collection</span></div>
                                <div v-if="typesFiltered" class="tag" :class="property.ContractType.toLowerCase()"><span>{{ property.ContractType }}</span></div>
                            </div>

                        </div>
                    </a>
                    <div class="p-15">
                        <div class="property-card--mls">
                            <p><span class="font-weight-medium">{{ trans('properties.ref_id') }}</span>: {{ property.MLS }}</p>
                        </div>
                        <div class="property-card--price">
                            <a :href="this.propertyLink">
                                <div v-if="property.HidePriceFromWeb">
                                    <h4 v-html="property.Price"></h4>
                                </div>
                                <div v-else>
                                    <h4>&euro; {{ $helpers.addCommas(property.Price) }} <span v-if="property.Period" v-html="property.Period"></span></h4>
                                </div>
                            </a>
                        </div>
                        <div class="property-card--property-type"><p>{{ property.PropertyType }}</p></div>
                        <div class="property-card--location">
                            <icon-pin />
                            <p class="ml-5">{{ this.getLcationName() }}, Malta</p>
                        </div>
                        <div class="property-card--location" v-if="property.TransactionType == 'For Rent'">
                            <p>
                                <icon-calendar class="mx-2 float-left" /><span class="mt-3 float-left" v-html="this.chanceOfAvailability()"></span>
                            </p>
                        </div>
                        <div class="property-card--location" v-else>
                            <p><icon-calendar class="mx-2 fade" /></p>
                        </div>
                    </div>
                </div>

                <div class="p-15">
                    <div class="property-card--share-compare">
                        <share-compare-selector :property="property"></share-compare-selector>
                    </div>
                    <div class="property-card--information--stats">
                        <span>
                            <icon-bed /><span class="ml-5 leading-none">{{ property.TotalBedrooms }} {{ trans('properties.beds') }}</span>
                        </span>
                        <span>
                            <icon-bath /><span class="ml-5 leading-none">{{ property.TotalBathrooms }} {{ trans('properties.baths') }}</span>
                        </span>
                        <span v-if="property.TotalSqm && property.TotalSqm > 1">
                            <icon-size /><span class="ml-5 leading-none">{{ property.TotalSqm }} {{ trans('properties.sqm') }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style>
</style>

<script>
export default {
    props: {
        property: Object,
        favourite: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            locale: document.getElementsByTagName('html')[0].getAttribute('lang').replace('_', '-'), 
            isLoading: true,
            propertyLink: '',
        }
    },

    computed: {
        typesFiltered() {
            return (this.property.ContractType.toLowerCase() !== 'open')
        },
        isCollection() {
            return Array.isArray(this.property.MarketingCategoryIds) ? this.property.MarketingCategoryIds.includes(11) : false;
        },
    },

    methods: {

        /**
         * Returns the property link
         */
        getPropertyLink(refId) {

            // Check for impersonation in url
            if(!this.$store.getters['ListingSearch/get'].Impersonate) {
                this.isLoading = false;
                this.propertyLink = '/listings/' + refId;

                return;
            }

            axios.post('/api/properties/' +  refId + '/get-encrypted-url', { IncriptedUserId: this.$store.getters['ListingSearch/get'].Impersonate })
                .then((response) => {
                    this.propertyLink = '/listings/share/' + response.data.data.URL;
                })
                .catch((error) => {
                    this.propertyLink = '/listings/' + refId;
                }).finally(() => {
                    this.isLoading = false;
                });
        },

        /**
         * Returns the location name of property
         */
        getLcationName() {
            var location = this.property.Town;
            if( this.property.Zone ) { location += ', ' + this.property.Zone; }

            return location;
        },

        /**
         * Formats the chance of availability
         */
        chanceOfAvailability() {
            if(this.property.TransactionType != 'For Rent') {
                return '';
            }

            var colorClass = 'success';
            var chanceOfAvailabilityTranslation = 'High';

            if( this.property.AvailabilityText == 'High' ) {
                colorClass = 'success';
                chanceOfAvailabilityTranslation = i18n.properties.high;
            }
            else if( this.property.AvailabilityText == 'Medium' ) {
                colorClass = 'warning';
                chanceOfAvailabilityTranslation = i18n.properties.medium;
            }
            else if( this.property.AvailabilityText == 'Low' ) {
                colorClass = 'danger';
                chanceOfAvailabilityTranslation = i18n.properties.low;
            }

            var text = '<span class="ml-5 text-' + colorClass + ' border border-' + colorClass + ' rounded-sm px-10 py-5">';
                text += '<span class="mr-5 bg-' + colorClass + ' rounded-circle px-5 py-5 d-inline-block"></span>' + chanceOfAvailabilityTranslation;
            text += '</span>';

            return i18n.properties.chance_of_availability + ' ' + text;
        }
        
    },

    mounted() {
        this.getPropertyLink( this.property.MLS );
    },

    watch: {
        property(updatedData) {
            this.getPropertyLink( this.property.MLS );
        }
    },
    
}
</script>
